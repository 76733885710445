<template>
    <div id="job">
        <transition name="slide-fade" mode="out-in">
        <div class="mcontainer" v-if="this.$route.name === 'Job' ">
        <div class="flex justify-between relative mb-3">
            <div class="flex-1">
                <h2 class="text-lg font-semibold"> Career </h2>
                <p>Temukan lowongan kerja dan bagikan kesempatan kerja.</p>
            </div>
        </div>
        <div class="flex row">
            <div class="col-md-4 order-md-2 mb-4 containerSticky">
            <vue-sticky-sidebar containerSelector=".containerSticky" innerWrapperSelector=".uk-sticky" :topSpacing='100'>
                <div uk-sticky="offset:100" class="uk-sticky card p-3">
                    <h4 class="text-lg font-semibold mb-3"> Categories </h4>
                    <ul class="list-checkbox">
                        <li><b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Category 1</b-form-checkbox></li>
                        <li><b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Category 2</b-form-checkbox></li>
                        <li><b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Category 3</b-form-checkbox></li>
                        <li><b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Category 4</b-form-checkbox></li>
                        <li><b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Category 5</b-form-checkbox></li>
                    </ul>
                    <h4 class="text-lg font-semibold mb-3"> Tags </h4>
                    <div class="flex flex-wrap gap-2">
                        <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Computers</a>
                        <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Business</a>
                        <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> News</a>
                        <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Architecher</a>
                        <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Technolgy</a>
                        <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Music</a>
                    </div>
                </div>
            </vue-sticky-sidebar>
            </div>
            <div class="col-md-8 order-md-1">
                <div class="px-1">
                    <transition name="fade" mode="out-in">
                        <div class="job-list card" v-if="loading">
                            <ListJob v-for="job in jobs" :key="job.id" :data="job" />
                        </div>
                        <div class="job-list p-4 card" v-if="!loading">
                            <content-placeholders v-for="index in 3" :key="index" class="my-3">
                            <content-placeholders-heading :img="true" />
                            <content-placeholders-text :lines="3" />
                            </content-placeholders>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        </div>
        <router-view/>
        </transition>
    </div>
</template>
<script>
    import ListJob from '@/components/ListJob'
    import vueStickySidebar from 'vue-sticky-sidebar'
    import axios from 'axios'

    export default {
        name: 'Job',
        components: {
            ListJob,
            vueStickySidebar
        },
        data () {
            return {
                jobs: [],
                loading: false
            }
        },
        async created () {
            try {
                const res = await axios.get('job')
                this.jobs = res.data.result
                this.loading = true
            } catch (e) {
                console.error(e)
            }
        }
    }
</script>
